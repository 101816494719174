import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {ANONYMOUS, loadPaymentWidget,} from "@tosspayments/payment-widget-sdk"
import {BrowserView, MobileView} from 'react-device-detect'
import {addComma, branchDict, saleDict} from "./config.js"


const Paying = () => {
    const navigate = useNavigate();
    const [payData, setPayData] = useState(null);
    const [check, setCheck] = useState(true);

    const location = useLocation();
    console.log(location)
    const [data, setData] = useState(location.state);
    console.log(data)


    const widgetClientKeyGHM = process.env.REACT_APP_keyGHM;
    const widgetClientKeyYUD = process.env.REACT_APP_keyYUD;
    const widgetClientKeyANAM = process.env.REACT_APP_keyANAM;
    const widgetClientKeyYS = process.env.REACT_APP_keyYS;
    const widgetClientKeyELJ = process.env.REACT_APP_keyELJ;
    const customerKey = ANONYMOUS;
    const [paymentWidget, setPaymentWidget] = useState(null);
    const paymentMethodsWidgetRef = useRef(null);
    const [price, setPrice] = useState(null);
    const [initPrice, setInitPrice] = useState(null);

    {/*const history = createBrowserHistory();
    window.onbeforeunload = function () {
//        deleteRoom()
	    return '';
    }*/
    }
    {/*const preventClose = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        console.log("새로고침안함")
    };*/
    }
    {/*useEffect(() => {
        (() => {
            window.addEventListener("beforeunload", preventClose);
        })();
        return () => {
            window.removeEventListener("beforeunload", preventClose);
    };}, []);*/
    }

    {/*const preventGoBack = () => {
        history.push(null, '', history.location.href);
        window.alert("페이지를 이동하시면 예약을 처음부터 다시 진행하셔야 합니다.")
    };

  useEffect(() => {
    (() => {
      history.push(null, '', history.location.href);
      window.addEventListener('popstate', preventGoBack);
    })();

    return () => {
      window.removeEventListener('popstate', preventGoBack);
    };
  }, []);*/
    }

    const deleteRoom = (event) => {
        fetch(process.env.REACT_APP_serviceUrl + "/reservation"//stgUrl + "/reservation"
            , {
                credentials: 'include', method: "DELETE", headers: {
                    "Content-Type": "application/json",
                },
            }).then(res => res.json())
            .then(json => {
                console.log(json)
                if (json.status == "success") {
                    window.location.href = "/book"
                } else {
                    window.alert(json.message)
                    window.location.href = "/book"
                }

            })

    }
    const fetchPaymentWidget = async () => {
        try {
            let widgetClientKey = "";
            if (data.branch == "Gwanghwamun") {
                widgetClientKey = widgetClientKeyGHM
            } else if (data.branch == "Yeouido") {
                widgetClientKey = widgetClientKeyYUD
            } else if (data.branch == "Ku") {
                widgetClientKey = widgetClientKeyANAM
            } else if (data.branch == "Yeoksam") {
                widgetClientKey = widgetClientKeyYS
            } else if (data.branch == "Eulji3") {
                widgetClientKey = widgetClientKeyELJ
            }

            const loadedWidget = await loadPaymentWidget(widgetClientKey, customerKey);
            setPaymentWidget(loadedWidget);
        } catch (error) {
            console.error("Error fetching payment widget:", error);
        }
    };

    useEffect(() => {
        if (data == null) {
            deleteRoom()
            window.alert("비정상적인 접근입니다. 처음부터 예약을 진행해주세요.")
            window.location.href = "/book"
        } else {
            setPrice(data.price)
            setInitPrice(data.price)
            fetchPaymentWidget();
        }

    }, []);

    useEffect(() => {
        if (paymentWidget == null) {
            return;
        }

        const paymentMethodsWidget = paymentWidget.renderPaymentMethods("#payment-widget", {value: price}, {variantKey: "DEFAULT"});

        paymentMethodsWidget.on('customRequest', paymentMethodKey => {
            if (paymentMethodKey === 'NAVERPAY') {
                console.log('NAVERPAY')
            }
        })

        paymentWidget.renderAgreement("#agreement", {variantKey: "AGREEMENT"});

        paymentMethodsWidgetRef.current = paymentMethodsWidget;
    }, [paymentWidget, price]);


    useEffect(() => {
        const paymentMethodsWidget = paymentMethodsWidgetRef.current;

        if (paymentMethodsWidget == null) {
            return;
        }

        paymentMethodsWidget.updateAmount(price);
    }, [price]);

    const handlePayment = () => {
        console.log({
            "amount": price,
            "name": data.name,
            "mobileNum": data.phone,
            "email": data.email, //                "branch": data.branch
        })
        fetch(process.env.REACT_APP_serviceUrl + "/payment", {
            credentials: 'include', method: "POST", headers: {
                "Content-Type": "application/json",
            }, body: JSON.stringify({
                "amount": price,
                "name": data.name,
                "mobileNum": data.phone,
                "email": data.email, //                "branch": data.branch
            })
        }).then(res => res.json())
            .then(json => {
                console.log("handlePaymentRequest")
                console.log(json)
                if (json.status == "success") {
                    setPayData(json.data)
                    console.log(json.data)
                } else {
                    window.alert(json.message)
                    window.location.href = '/book'
                }
            })
    }

    useEffect(() => {
        handlePaymentRequest()
    }, [payData])


    const handlePaymentRequest = async () => {
        // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
        // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
        try {
            await paymentWidget?.requestPayment({
                orderId: payData.orderId,
                orderName: `Room ${data.start.split("_")[0]} (${data.startTime}-${data.endTime})`,
                customerName: data.name,
                customerEmail: data.email,
                customerMobilePhone: data.phone,
                successUrl: `${window.location.origin}/process`,
                failUrl: `${window.location.origin}/fail`,
            });
        } catch (error) {
            console.error("Error requesting payment:", error);
            window.alert(error.message)
        }

    };


    const today = new Date();
    console.log("minutes", today.getMinutes())
    console.log("minutes", Math.ceil(today.getMinutes() / 10))
    const [date, setDate] = useState(today);
    const [hourNow, setHourNow] = useState(today.getHours());
    const [hour, setHour] = useState(today.getHours());
    const [day, setDay] = useState(0);
    const [select, setSelect] = useState([]);
    const [step, setStep] = useState(3);

    const nextStep = (event) => {
        setStep(prev => prev + 1)
        console.log(step)
    }
    const prevStep = (event) => {
        setStep(prev => prev - 1)
        console.log(step)
    }


    return (<>
            <div className="mypage-wrap">
                <div className="inner">

                    <BrowserView>
                        <div className={step >= 0 ? "rectangle left on" : "rectangle left"} id="1"> STEP 1 : 지점 선택</div>
                        <div className={step >= 1 ? "rectangle on" : "rectangle"} id="2"> STEP 2 : 방 선택</div>
                        <div className={step >= 2 ? "rectangle on" : "rectangle"} id="3"> STEP 3 : 예약 정보</div>
                        <div className={step >= 3 ? "rectangle on" : "rectangle"} id="4"> STEP 4 : 요금 결제</div>
                        <div className={step >= 4 ? "rectangle right on" : "rectangle right"} id="5"> STEP 5 : 예약완료
                        </div>
                    </BrowserView>
                    <MobileView>
                        <div className={step >= 0 ? "rectangle left on" : "rectangle left"} id="1"> 1. 지점선택</div>
                        <div className={step >= 1 ? "rectangle on" : "rectangle"} id="2"> 2. 방선택</div>
                        <div className={step >= 2 ? "rectangle on" : "rectangle"} id="3"> 3. 정보</div>
                        <div className={step >= 3 ? "rectangle on" : "rectangle"} id="4"> 4. 결제</div>
                        <div className={step >= 4 ? "rectangle right on" : "rectangle right"} id="5"> 5. 완료</div>
                    </MobileView>
                    {/*<hr/>*/}
                    <div className="form-box round">
                        {data == null ? <div/> : <div className="form-group">
                            {/*<span className="tip title">예약 정보</span>*/}
                            <div>
                                예약장소 : <span
                                className="calc grey">{data.start == "9_9_9" ? "" : (branchDict[data.branch] + " - Room " + data.start.split("_")[0])}</span>
                            </div>
                            {/*<div>
                            예약시간 : <span className="calc highlight">{data.startTime.slice(0,4)+"."+data.startTime.slice(4,6)+"."+data.startTime.slice(6,8)+" "+data.startTime.slice(8,10)+"시 "+data.startTime.slice(10,12)+"분 - "+data.endTime.slice(0,4)+"."+data.endTime.slice(4,6)+"."+data.endTime.slice(6,8)+" "+(data.endTime.slice(10,12)=="50"?parseInt(data.endTime.slice(8,10))+1:data.endTime.slice(8,10))+"시 "+(data.endTime.slice(10,12)=="50"? "00":parseInt(data.endTime.slice(10,12))+10)+"분" }</span>
                        </div>*/}
                            <div>
                                시작시간 : <span
                                className="calc grey">{data.startTime.slice(0, 4) + "." + data.startTime.slice(4, 6) + "." + data.startTime.slice(6, 8) + " " + data.startTime.slice(8, 10) + "시 " + data.startTime.slice(10, 12) + "분"}</span>
                            </div>
                            <div>
                                종료시간 : <span
                                className="calc grey">{data.endTime.slice(0, 4) + "." + data.endTime.slice(4, 6) + "." + data.endTime.slice(6, 8) + " " + (data.endTime.slice(10, 12) == "50" ? parseInt(data.endTime.slice(8, 10)) + 1 : data.endTime.slice(8, 10)) + "시 " + (data.endTime.slice(10, 12) == "50" ? "00" : parseInt(data.endTime.slice(10, 12)) + 10) + "분"}</span>
                            </div>
                            <div>
                                결제하실 금액 : <span className="calc grey">{addComma(price)}원</span>
                            </div>
                            {/*<div>
                            예약일시 : {start=="9_9_9"? <span /> : <span className="calc highlight">2024년 {date.getMonth()+1}월 {date.getDate()}일 {hour+parseInt(start.split("_")[1])-1}:{start.split("_")[2]=="1"?"00":((parseInt(start.split("_")[2])-1)*10)} - {hour+parseInt(end.split("_")[1])-1}:{(parseInt(end.split("_")[2]))*10}</span>}
                        <div>
                        </div>
                            금액 : <span className="calc highlight">{price<0? "0": addComma(price)}원</span>
                        </div>*/}
                        </div>}
                    </div>

                    <div>
                        {/* 할인 쿠폰 */}
                        <label htmlFor="coupon-box">
                            <input
                                id="coupon-box"
                                type="checkbox"
                                checked={check}
                                className="coupon-box"
                                onChange={(event) => {
                                    setPrice(event.target.checked ? initPrice : initPrice / (1 - saleDict[data.branch]["percent"]));
                                    setCheck(event.target.checked)
                                }}
                            />
                            <span>{saleDict[data.branch]["reason"]} </span>
                        </label>
                        {/* 결제 UI, 이용약관 UI 영역 */}
                        <div id="payment-widget"/>
                        <div id="agreement"/>
                        {/* 결제하기 버튼 */}
                        <div>
                            <BrowserView>
                                {/*<div className="btn-wrap">
                              <button className="btn btn-black left" onClick={()=>navigate( '/info', { state: data } )}>이전으로</button>*/}
                                <button className="btn btn-black right" onClick={handlePayment}>결제하기</button>
                                <div className="txt-underline" onClick={() => navigate('/info', {state: data})}>
                                    이전단계로
                                </div>
                                {/*</div>*/}
                            </BrowserView>
                            <MobileView>
                                {/*<button className="btn btn-black left" onClick={()=>navigate( '/info', { state: data } )}>이전으로</button>
                            <button className="btn btn-black right" onClick={handlePaymentRequest}>결제하기</button>*/}
                                <button className="btn btn-black right" onClick={handlePayment}>결제하기</button>
                                <div className="txt-underline" onClick={() => navigate('/info', {state: data})}>
                                    이전단계로
                                </div>
                            </MobileView>
                        </div>
                    </div>
                </div>
            </div>
        </>);
};

export default Paying;


